/**
 * parses a title from the kvv feed to separate chunks for rendering
 * @param {String} title
 * @returns {object} three properties: lines, content and timeframe
 */
export const parseKvvTickerTitle = (title) => {
  // regex was constructed and can be debugged with https://regexr.com/606qb
  // the three chunks we want are defined as named capture groups
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Groups_and_Ranges#using_named_groups
  // - ^                      start of string
  // - (?<lines>.*?)          named capture group, non-greedy (https://stackoverflow.com/a/2074466), so it stops at first occurence of the following separator
  // - \s+-\s+                this is the separator: whitespace with - in between
  // - (?<content>(?:.|\n)*) named capture group, greedy, because otherwise will stop at first opening parenthesis
  //                          may contain newlines (. matches any characer exept newline)
  // - \s*                    separator whitespace
  // - \((?<timeframe>.*?)\)   named capture group, non-greedy, within parentheses
  // - $                      end of string
  const regexp = /^(?<lines>.*?)\s+-\s+(?<content>(?:.|\n)*)\s*\((?<timeframe>.*?)\)$/g
  const result = regexp.exec(title)
  const {
    lines,
    content,
    timeframe,
  } = result?.groups ?? {}

  // trim every chunk to get rid of whitespace that was unintentionally captured by regex
  return {
    lines: lines.trim(),
    content: content.trim(),
    timeframe: timeframe.trim(),
  }
}
