import React from 'react'
import useLargeTxt from 'hooks/useLargeTxt'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core'

import NewsList from 'components/News/NewsList'
import TickerList from 'components/News/TickerList'
import { useTranslation } from 'react-i18next'

/**
 *  screen with news list and news ticker
 */
const NewsScreen = ({
  showNewsLinks,
  disableExternLinks,
}) => {
  const classes = useStyles()
  const largeTxt = useLargeTxt()
  const { t } = useTranslation()

  return (
    <Grid
      alignItems='stretch'
      container
      direction='column'
      wrap='nowrap'
      spacing={2}
      className={classes.baseContainer}
    >
      <Grid item>
        <Typography component='p' className={clsx(classes.margin, largeTxt && classes.overlineLargeTxt)} variant='overline'>{t('newsScreen.headlineTicker')}</Typography>
        <TickerList url='/proxy/ticker_rss.xml' disableExternLinks={disableExternLinks} />
      </Grid>
      <Grid item>
        <NewsList url='/proxy/feed.xml' showNewsLinks={showNewsLinks} />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1, 3),
  },
  overlineLargeTxt: {
    margin: theme.spacing(0, 3, 2),
  },
  baseContainer: {
    width: `calc(100vw - ${theme.spacing(2)}px)`,
    overflow: 'hidden',
  },
}))

NewsScreen.propTypes = {
  disableExternLinks: PropTypes.bool,
  showNewsLinks: PropTypes.bool,
}
NewsScreen.defaultProps = {
  showHeader: true,
}
export default NewsScreen
