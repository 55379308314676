import React, { useState, useEffect } from 'react'
import Parser from 'rss-parser'
import PropTypes from 'prop-types'
import { NewsCard } from './NewsCard'

/**
 * fetches rss feed from given url and renders items as NewsCards
 */
const NewsList = ({ url, showNewsLinks }) => {
  const [rssItems, setRssItems] = useState(null)
  useEffect(() => {
    const parser = new Parser(
      {
        headers: {
          'Access-Control-Allow-Headers': 'access-control-allow-headers,access-control-allow-methods, access-control-allow-origin, user-agent',
          'Access-Control-Allow-Methods': 'GET,PUT,OPTIONS',
          'Access-Control-Allow-Origin': '*',
        },
        requestOptions: {
          headers: {
            'ACCESS-CONTROL-REQUEST-METHOD': 'GET',
          },
        },
      }
    );
    (async () => {
      const feed = await parser.parseURL(url)
      setRssItems(feed.items)
    })()
  }, [url])

  if (rssItems) {
    return rssItems.map((news) => {
      const { guid, enclosure, title, pubDate, content, link } = news
      // stupid usage of : in xml tree is stupid (ﾉ °益°)ﾉ 彡 ┻━┻
      const contentExtended = news['content:encoded'] || content
      return (
        <NewsCard
          key={guid}
          guid={guid}
          enclosure={enclosure}
          title={title}
          pubDate={pubDate}
          content={content}
          contentExtended={contentExtended}
          link={link}
          showNewsLinks={showNewsLinks}
        />
      )
    }
    )
  }
  return null
}

NewsList.propTypes = {
  url: PropTypes.string,
  showNewsLinks: PropTypes.bool,
}

export default NewsList
