import React, { useMemo, useState } from 'react'
import useLargeTxt from 'hooks/useLargeTxt'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Typography,
  makeStyles,
  CardActionArea,
  CardContent,
  Card,
} from '@material-ui/core'

import { parseKvvTickerTitle } from 'util/news'
import NewsAndTickerDetailsDialog from './NewsAndTickerDetailsDialog'

/**
 *  Provides single ticker news card in swipeable list
 * @param title
 * @param link
 * @param disableExternLinks
 * @returns {JSX.Element}
 * @constructor
 */
export const TickerCard = ({
  title,
  link,
  disableExternLinks,
}) => {
  const classes = useStyles()

  const {
    lines,
    content,
    timeframe,
  } = useMemo(() => parseKvvTickerTitle(title), [title])

  const largeTxt = useLargeTxt()
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleOpen = () => {
    setDialogOpen(true)
  }

  return (
    <Card
      className={clsx(classes.rootStandalone, largeTxt && classes.rootLargeTxt)}
      elevation={0}
      variant='elevation'
    >
      <CardActionArea {...(!disableExternLinks ? { target: '_blank', href: link, rel: 'noopener' } : {})}>
        <CardContent onClick={disableExternLinks ? handleOpen : null} className={clsx(largeTxt && classes.cardLargeTxt, classes.cardStandalone)}>
          <Typography
            className={clsx(classes.pr1, classes.ellipsis, largeTxt ? classes.captionLarge : classes.caption)}
            variant='caption'
            color='inherit'
          >
            {timeframe}
          </Typography>
          <Typography
            className={clsx(classes.pb1, classes.pt1, classes.pr1, classes.ellipsis, largeTxt ? classes.headlineLarge : classes.headline)}
            component='div'
            variant='subtitle1'
          >
            {lines}
          </Typography>
          <Typography
            className={clsx(classes.contentContainerPort, classes.pr1, classes.ellipsisMultiline, largeTxt && classes.ellipsisMultilinelargeTxt, largeTxt ? classes.body2Large : classes.body2)}
            component='div'
            variant='body2'
            color='textSecondary'
          >
            {content}
          </Typography>
        </CardContent>
      </CardActionArea>
      {disableExternLinks && (
        <NewsAndTickerDetailsDialog open={dialogOpen} onClose={handleClose} headline={lines} content={content} />
      )}
    </Card>
  )
}

TickerCard.propTypes = {
  /* Title field from rss feed */
  title: PropTypes.string,
  /* Link from rss feed */
  link: PropTypes.string,
  disableExternLinks: PropTypes.bool,
}
TickerCard.defaultProps = {
  disableExternLinks: false,
}

const useStyles = makeStyles(theme => ({
  rootStandalone: {
    width: 213,
  },
  root: {
    marginRight: 60,
    marginBottom: theme.spacing(1),
  },
  rootLargeTxt: {
    marginRight: 60,
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  cardStandalone: {
    height: 71,
    padding: theme.spacing(2, 0, 2, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  cardLargeTxt: {
    height: 88,
    padding: theme.spacing(2, 0, 2, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    height: 129,
  },
  lh: {
    lineHeight: 1.2,
  },
  pb1: {
    paddingBottom: theme.spacing(),
  },
  pt1: {
    paddingTop: theme.spacing(),
  },
  pr1: {
    paddingRight: theme.spacing(),
  },
  contentContainerPort: {
    maxHeight: 28,
    overflow: 'hidden',
  },
  contentContainer: {
    maxHeight: 80,
    marginBottom: theme.spacing(),
    overflow: 'hidden',
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  ellipsisMultiline: {
    height: '28px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  ellipsisMultilinelargeTxt: {
    height: '32px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  fadeOutBottom: {
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: 12,
      background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%)',
    },
  },
  captionLarge: {
    fontSize: '20px',
    lineHeight: '22px',
    letterSpacing: 0.1,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  caption: {
    fontSize: '10px',
    lineHeight: '11px',
    letterSpacing: 0.1,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  body2Large: {
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: 0.12,
  },
  body2: {
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: 0.12,
  },
  headlineLarge: {
    fontSize: '28px',
    lineHeight: '32px',
    letterSpacing: 0.09,
  },
  headline: {
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: 0.09,
  },
}))
