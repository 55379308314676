import React, { useState, useEffect } from 'react'
import Parser from 'rss-parser'
import useLargeTxt from 'hooks/useLargeTxt'
import PropTypes from 'prop-types'

import { TickerCard } from './TickerCard'
import {
  Box,
  makeStyles,
} from '@material-ui/core'

import Swiper from 'react-id-swiper'
import 'swiper/swiper-bundle.css'

/**
 *  Provides swipeable List with news ticker
 * @param url
 * @param disableExternLinks
 * @returns {JSX.Element}
 * @constructor
 */
const TickerList = ({ url, disableExternLinks }) => {
  const [rssItems, setRssItems] = useState(null)

  // XXX Classes not needed, however useStyles must be called to "activate" global css
  const classes = useStyles()

  const largeTxt = useLargeTxt()

  useEffect(() => {
    const parser = new Parser(
      {
        headers: {
          'Access-Control-Allow-Headers': 'access-control-allow-headers,access-control-allow-methods, access-control-allow-origin, user-agent',
          'Access-Control-Allow-Methods': 'GET,PUT,OPTIONS',
          'Access-Control-Allow-Origin': '*',
        },
        requestOptions: {
          headers: {
            'ACCESS-CONTROL-REQUEST-METHOD': 'GET',
          },
        },
      }
    );
    (async () => {
      const feed = await parser.parseURL(url)
      setRssItems(feed.items)
    })()
  }, [url])

  return (
    <Box className={classes.border}>
      <Swiper slidesPerView={!largeTxt ? 2.15 : 1}>
        {rssItems && rssItems.map(({ guid, title, link }) => {
          return (
          // XXX extra div needed for swiper (markup) to work correctly
            <div key={guid}>
              <TickerCard
                guid={guid}
                title={title}
                link={link}
                disableExternLinks={disableExternLinks}
              />
            </div>
          )
        })}
      </Swiper>
    </Box>
  )
}

TickerList.propTypes = {
  url: PropTypes.string,
  disableExternLinks: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.swiper-slide': {
      width: '221px',
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        bottom: '15%',
        height: '71px',
        borderRight: '1px solid rgba(0,0,0,0.13)',
      },
    },
    '.swiper-slide-active': {
      '&::after': {
        borderRight: 'none',
      },
    },
  },
  border: {
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.18)',
  },
}))

export default TickerList
