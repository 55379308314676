import React, { useState } from 'react'
import {
  Typography,
  makeStyles,
  Paper,
  Link,
  CardMedia,
  CardContent,
  Card,
} from '@material-ui/core'
import useLargeTxt from 'hooks/useLargeTxt'

import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import { DateTime } from 'luxon'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import NewsAndTickerDetailsDialog from './NewsAndTickerDetailsDialog'

/**
 * renders a single news as Card
 */
export const NewsCard = ({ guid, enclosure, title, pubDate, content, contentExtended, link, showNewsLinks }) => {
  const { t } = useTranslation()

  const classes = useStyles()
  // XXX this is a workaround that parses only the date part without weekday and time of a string like this one:
  // Di, 27. Jul 2021 00:04:02 +0200
  // because KVV does not adhere to the RSS spec: https://validator.w3.org/feed/check.cgi?url=https%3A%2F%2Fwww.kvv.de%2Ffeed.xml
  // however this still breaks in months were the german and english abbreviations of months diverge (Mar, May, Oct, Dec)
  const displayDate = pubDate?.substr(4, 12).replace('.', '')
  const formDate = DateTime.fromFormat(displayDate, 'dd LLL yyyy').ts
  const largeTxt = useLargeTxt()

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleClose = () => {
    setDialogOpen(false)
  }

  const handleOpen = () => {
    setDialogOpen(true)
  }

  return (
    <Card
      className={clsx(largeTxt && classes.cardContainerLargeTxt, classes.cardContainerStandalone)}
      key={guid}
      elevation={0}
      variant='elevation'
    >
      {enclosure && !largeTxt &&
        <LazyLoad height={164}>
          <CardMedia
            className={classes.mediaContentStandalone}
            image={enclosure?.url}
            title={title}
            component='img'
          />
        </LazyLoad>}
      <CardContent onClick={!showNewsLinks ? handleOpen : null} className={clsx(largeTxt && classes.contentSizeLargeTxt)} component={Paper} elevation={2}>
        <Typography color='textSecondary' variant='caption' className={largeTxt ? classes.captionLarge : classes.caption}>
          {t('formatting.dateWithYear', { value: formDate })}
        </Typography>
        <Typography className={clsx(classes.titleStandalone, largeTxt ? classes.headlineLarge : classes.headline)} variant='h5' component='div'>
          {title}
        </Typography>
        <Typography variant='body2' component='p' className={clsx(largeTxt && classes.titleLargeTxt, classes.ellipsisMultiline3, largeTxt ? classes.body2Large : classes.body2)}>
          {content}
        </Typography>
        {
          showNewsLinks &&
            <Link className={classes.link} target='_blank' href={link} rel='noopener'>
              {t('ui.more')}
            </Link>
        }
      </CardContent>
      {!showNewsLinks && (
        <NewsAndTickerDetailsDialog open={dialogOpen} onClose={handleClose} headline={title} content={contentExtended} />
      )}
    </Card>
  )
}

NewsCard.propTypes = {
  guid: PropTypes.string,
  enclosure: PropTypes.object,
  title: PropTypes.string,
  pubDate: PropTypes.string,
  content: PropTypes.string,
  contentExtended: PropTypes.string,
  link: PropTypes.string,
  showNewsLinks: PropTypes.bool,
}

NewsCard.defaultProps = {
  showNewsLinks: true,
}

const useStyles = makeStyles(theme => ({
  cardContainerStandalone: {
    padding: theme.spacing(1, 2, 1, 2),
    display: 'flex',
    height: '164px',
    width: `calc(100% - ${theme.spacing(2)}px)`,
  },
  cardContainerLargeTxt: {
    padding: theme.spacing(1, 2, 1, 2),
    display: 'block',
    height: 'auto',
  },
  contentSizeStandalone: {
    width: '60%',
    height: '100%',
    flex: '1 1 auto',
  },
  link: {
    marginLeft: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    color: theme.palette.secondary.main,
    ...theme.typography.body2,
  },
  '@global': {
    '.lazyload-wrapper': {
      width: '40%',
      minWidth: '200px',
    },
  },
  mediaContentStandalone: {
    width: '100%',
    height: '100%',
  },
  titleStandalone: {
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    maxHeight: 44,
  },
  ellipsisMultiline3: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    maxHeight: 42,
  },
  cardContainer: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  contentSize: {
    width: '60%',
    height: '100%',
    flex: '1 1 auto',
  },
  contentSizeLargeTxt: {
    height: '100%',
    width: '100%',
  },
  pb1: {
    paddingBottom: theme.spacing(),
  },
  mediaContent: {
    minHeight: 194,
  },
  title: {
    lineHeight: 1.2,
    paddingBottom: theme.spacing(),
  },
  titleLargeTxt: {
    maxHeight: '5rem',
    marginBottom: '.5rem',
    lineHeight: 1.1,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  captionLarge: {
    fontSize: '20px',
    lineHeight: '22px',
    letterSpacing: 0.1,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  caption: {
    fontSize: '10px',
    lineHeight: '11px',
    letterSpacing: 0.1,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  body2Large: {
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: 0.12,
  },
  body2: {
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: 0.12,
  },
  headlineLarge: {
    color: '#000',
    fontSize: '36px',
    letterSpacing: 0,
    lineHeight: '44px',
  },
  headline: {
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: 0,
    color: '#000000',
  },
}))
