import React from 'react'
import { I18nextProvider } from 'react-i18next'

import NewsScreen from './components/Screens/NewsScreen'
import i18next from 'frameworks/i18next'

const App = () => {
  const i18n = i18next()

  // search in url fir relevant search params
  const { search } = window.location
  const hasExternalLinkingEnabled = search.includes('enableLinking=true')

  return (
    <I18nextProvider i18n={i18n}>
      <NewsScreen showNewsLinks={hasExternalLinkingEnabled} disableExternLinks={!hasExternalLinkingEnabled} />
    </I18nextProvider>
  )
}

export default App
