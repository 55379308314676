import React from 'react'
import { Avatar, Dialog, DialogContent, Grid, Typography, makeStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import CloseIcon from '@material-ui/icons/Close'
import useLargeTxt from 'hooks/useLargeTxt'

/**
 * renders a dialog with news details
 */
const NewsAndTickerDetailsDialog = ({ onClose, headline, content, open }) => {
  const largeTxt = useLargeTxt()

  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose} classes={{ paperWidthSm: classes.maxModal, paper: classes.paper, paperScrollPaper: classes.paperScrollPaper }}>
      <DialogContent className={classes.content} dividers>
        <Grid container>
          <Grid item>
            <Avatar className={classes.avatar} onClick={onClose}>
              <CloseIcon />
            </Avatar>
            <Typography variant='h5' component='div' className={largeTxt ? classes.headlineLarge : classes.headline}>{headline}</Typography>
            <div className={largeTxt ? classes.body2Large : classes.body2} dangerouslySetInnerHTML={{ __html: content }} />
          </Grid>
        </Grid>
      </DialogContent>

    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    // disables external links...all links ¯\_(ツ)_/¯
    a: {
      pointerEvents: 'none',
    },
  },
  maxModal: {
    width: '100vw',
    height: '100vh',
    maxWidth: 'unset',
  },
  f00a: {
    flex: '0 0 auto',
  },
  f11a: {
    flex: '1 1 auto',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  avatar: {
    // TODO configure theme
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: '#12297A',
    position: 'absolute',
    top: 20,
    right: 20,
  },
  content: {
    minHeight: 400,
  },
  paper: {
    margin: 0,
  },
  paperScrollPaper: {
    maxHeight: 'unset',
    overflow: 'hidden',
  },
  body2Large: {
    ...theme.typography.body2,
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: 0.12,
  },
  body2: {
    ...theme.typography.body2,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: 0.12,
  },
  headlineLarge: {
    color: '#000',
    fontSize: '36px',
    letterSpacing: 0,
    lineHeight: '44px',
    marginRight: theme.spacing(4),
  },
  headline: {
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: 0,
    color: '#000000',
    marginRight: theme.spacing(4),
  },
}))

NewsAndTickerDetailsDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  headline: PropTypes.string,
  content: PropTypes.string,
}

export default NewsAndTickerDetailsDialog
