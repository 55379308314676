// add react polyfills according to https://github.com/facebook/create-react-app/tree/master/packages/react-app-polyfill
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'

// import roboto font https://next.material-ui.com/components/typography/#install-with-npm
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import './index.css'
import App from './App'

ReactDOM.render(
  <App />
  ,
  document.getElementById('root')
)
